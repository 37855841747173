<template>        
  <div class="mt-5 md:mt-0 md:col-span-2">
    <div class="shadow sm:rounded-md sm:overflow-hidden">
      <div class="px-4 py-5 bg-white space-y-6 sm:p-6">
        <div class="mt-5 md:mt-0 md:col-span-2">
          
          <div class="grid grid-cols-6 gap-6">
            <div class="col-span-6 sm:col-span-4">
              <label for="registration_response" class="block text-sm font-medium text-gray-700">Titel item</label>
              <input type="text" name="registration_response" id="registration_response" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="eventItemTitle">
            </div>

            <div class="col-span-6 sm:col-span-1">
              <label for="registration_response" class="block text-sm font-medium text-gray-700">Start tijd</label>
              <input type="text" name="registration_response" id="registration_response" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="eventItemStartsAt">
            </div>

            <div class="col-span-6 sm:col-span-1">
              <label for="registration_response" class="block text-sm font-medium text-gray-700">Eind tijd</label>
              <input type="text" name="registration_response" id="registration_response" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="eventItemEndsAt">
            </div>

            <div class="col-span-6 sm:col-span-6">
              <label for="extra_confirmation_txt" class="block text-sm font-medium text-gray-700">Korte omschrijving item</label>
              <textarea name="extra_confirmation_txt" id="extra_confirmation_txt" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="eventItemDescription" rows="5"></textarea>
              <!--<span class="text-sm text-gray-500">Deze tekst komt in de bevestigingsemail te staan. Een link naar de Teams meeting kan hier bijv. met een korte instructie worden geplaatst.</span>-->
            </div>
          </div>

          <button @click="addProgramEvent" type="submit" class="mt-4 inline-flex w-full mb-4 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
            <svg v-show="saving" class="animate-spin h-4 mr-2 w-4 rounded-full bg-transparent border-2 border-transparent border-opacity-50" style="border-right-color: white; border-top-color: white;" viewBox="0 0 24 24"></svg>
            {{saving ? 'Bezig': 'Voeg toe'}}
          </button>

          
          <div class="grid grid-cols-4 gap-4 w-full p-2 border mb-2" v-for="item, index in programEvents" :key="index" :class="index % 2 ? '':'bg-gray-100'">
              <div class="md:col-span-2 font-bold flex">
                {{item.title}}
                <div class="flex">
                  <EventForm :programEvent="item" :item-id="item.id" :thetitle="item.title" @updated="fetchProgramEvents"/>
                  <TrashCan32 class="h-5 w-5 ml-3 text-red-600 cursor-pointer" @click="deleteProgramEvent(item.id)" />
                </div>
              </div>
              <div class="md:col-span-2 font-bold text-right">
                {{item.start_time}} - {{item.end_time}}
              </div>
              <div class="md:col-span-4">
                {{item.description}}
              </div>
          </div>

        </div>
      </div>    
    </div>
  </div>

</template>

<script>
import { ref, onMounted, toRefs } from "vue";
import McademyItemService from '../../../services/admin/mcademy_items/service';
import { TrashCan32 } from '@carbon/icons-vue';
import EventForm from './EventForm';

export default {
  name: 'NewsitemProgram',
  props: {
    mcademy_item_id: {
      type: Number,
      default: null
    }
  },
  components: {
    TrashCan32,
    EventForm
  },
  setup(props) {
    const loading = ref(false);
    const saving = ref(false);
    const { mcademy_item_id } = toRefs(props);
    const programEvents = ref([]);
    const eventItemTitle = ref('');
    const eventItemDescription = ref('');
    const eventItemStartsAt = ref('');
    const eventItemEndsAt = ref('');

    const addProgramEvent = async function() {
      loading.value = true;
      await McademyItemService.addProgramEvent({mcademy_item_id:mcademy_item_id.value, title: eventItemTitle.value, description: eventItemDescription.value, start_time: eventItemStartsAt.value, end_time: eventItemEndsAt.value});
      await fetchProgramEvents()
      loading.value = false;
    }

    const deleteProgramEvent = async function(id) {
      if(confirm('are you sure?')) {
        loading.value = true;
        await McademyItemService.deleteProgramEvent(id);
        fetchProgramEvents()
        loading.value = false;
      }
    }

    const fetchProgramEvents = async function() {
      McademyItemService.getProgramEvents(mcademy_item_id.value).then(({data}) => {
        programEvents.value = data
      });  
    }

    onMounted(() => {
      fetchProgramEvents()
    });

    return {
      loading,
      saving,
      addProgramEvent,
      programEvents,
      eventItemTitle,
      eventItemDescription,
      eventItemStartsAt,
      eventItemEndsAt,
      deleteProgramEvent,
      fetchProgramEvents
    }
  },
  methods: {
    
  }
}
</script>
