<template>
  <div>
    <RequestQuote32 class="h-5 w-5 ml-3 cursor-pointer" aria-hidden="true" @click="open = true" />

    <TransitionRoot as="template" :show=open>
      <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="open = false">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <!-- This element is to trick the browser into centering the modal contents. -->
          <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div>
                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">
                    Programmadetails
                  </DialogTitle>
                </div>

                <form @submit.prevent="handleSave" method="POST">
                  
                  <div class="py-5 bg-white sm:p-6">
                    <div class="grid grid-cols-6 gap-6">
                      
                      <div class="col-span-6 sm:col-span-6">
                        <label for="title" class="block text-sm font-medium text-gray-700">Title</label>
                        <input type="text" name="title" id="title" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" :value="programEvent.title" @input="item.title = $event.target.value">
                      </div>

                      <div class="col-span-6 sm:col-span-6">
                        <label for="start_time" class="block text-sm font-medium text-gray-700">Van tijd</label>
                        <input type="text" name="start_time" id="start_time" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="item.start_time">
                      </div>

                      <div class="col-span-6 sm:col-span-6">
                        <label for="end_time" class="block text-sm font-medium text-gray-700">Tot tijd</label>
                        <input type="text" name="start_time" id="end_time" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="item.end_time">
                      </div>

                      <div class="col-span-6 sm:col-span-6">
                        <label for="extra_confirmation_txt" class="block text-sm font-medium text-gray-700">Korte omschrijving item</label>
                        <textarea name="extra_confirmation_txt" id="extra_confirmation_txt" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="item.description" rows="5"></textarea>
                      </div>

                    </div>
                  </div>
                  <div class="py-3 text-right sm:px-6">
                    <button type="button" @click="open=false" class="inline-flex justify-center py-2 px-4 border-2 border-gray-400 mr-2 shadow-sm text-sm font-medium rounded-md text-gray-400 bg-white hover:bg-gray-600 hover:text-white">
                      Klaar
                    </button>
                    <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
                      <svg v-show="saving" class="animate-spin h-4 mr-2 w-4 rounded-full bg-transparent border-2 border-transparent border-opacity-50" style="border-right-color: white; border-top-color: white;" viewBox="0 0 24 24"></svg>
                      {{saving ? 'saving': 'save'}}
                    </button>
                    
                  </div>
                  
                </form>
                
              </div>
              
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
import { ref, toRefs, watch } from "vue";
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import McademyItemService from '../../../services/admin/mcademy_items/service';
import { RequestQuote32 } from '@carbon/icons-vue'

export default {
  name: 'EventFormComponent',
  props: {
    programEvent: {
      type: Object,
    },
    itemId: {
      type: Number,
      default: 0,
    },
    thetitle: {
      type: String,
      default: '',
    }
  },
  emits: ['update:programEvent.title'],
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    RequestQuote32
  },
  setup(props) {
    const open = ref(false)
    const loading = ref(false);
    const saving = ref(false);
    let item = ref(null);
    let test = ref(null)

    const title = ref(null);

    
    let { programEvent, itemId, thetitle } = toRefs(props);
    item.value = programEvent;
    title.value = thetitle;

    watch(programEvent, (newValue, oldValue) => {
      console.log(oldValue, newValue)
      item.value = newValue;
    })

    console.log(itemId);
    test.value = itemId;

    return {
      loading,
      saving,
      item,
      open,
      test,
      title
    }
  },
  methods: {
    async handleSave() {
      this.saving = true;
      await McademyItemService.updateProgramEvent(this.item);
      this.$emit('updated');
      this.saving = false;
    }
  }
}
</script>
