<template>
  <main className="py-5 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 min-h-screen">
    <PageHeader title="Wijzig item" />

    <div class="mt-10 sm:mt-0" v-if="mcademyItem">
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900">General information</h3>
            <p class="mt-1 text-sm text-gray-600">
              Wijzig hier alle variabelen.
            </p>
            
          </div>
        </div>

        <div class="mt-5 md:mt-0 md:col-span-2">
          <McademyItemForm :mcademy_item="mcademyItem" />
        </div>
        
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900">Registraties</h3>
            <p class="mt-1 text-sm text-gray-600">
              Bekijk hier alle {{mcademyItem.McademyRegistrations.length}} registraties
            </p>
          </div>
        </div>

        <div class="mt-5 md:mt-0 md:col-span-2">
          <div v-if="mcademyItem">
            
              <div class="mt-5 md:mt-0 md:col-span-2">
                <div class="shadow sm:rounded-md sm:overflow-hidden">
                  <div class="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <div class="mt-5 md:mt-0 md:col-span-2">
                      
                      <div class="grid grid-cols-2 gap-4 mb-4">
                        <button class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500" @click="getRegistrations">Toon registraties</button>
                        <button class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500" @click="exportCsv">Download registraties</button>
                      </div>

                      <div class="grid grid-cols-4 gap-4 w-full">
                        <template v-for="registration, index in registrations" :key="index">
                          <div class="md:col-span-2">
                            {{registration.name}}
                            <div class="text-gray-400">{{registration.email}}</div>
                          </div>
                          <div class="md:col-span-2">
                            {{registration.company}}
                            <div v-if="registration.extra_info" class="text-gray-400">({{registration.extra_info}})</div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>    
                </div>
              </div>
            
          </div>
        </div>

        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900">Programma / Agenda</h3>
            <p class="mt-1 text-sm text-gray-600">
              Eventueel programma overzicht om op de eventpagina te tonen.<br><br>Dit is zeker niet vereist, maar kan bij langere of grotere events duidelijkheid en extra body aan het event geven.
            </p>
          </div>
        </div>

        <div class="mt-5 md:mt-0 md:col-span-2">
          <EventProgram :mcademy_item_id="mcademyItem.id" v-if="mcademyItem" />
        </div>
        
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900">Highlight image</h3>
            <p class="mt-1 text-sm text-gray-600">
              For social media. Upload a high resolution image. The image will be scaled automatically.
            </p>
          </div>
        </div>

        <div class="mt-5 md:mt-0 md:col-span-2">
          <div v-if="mcademyItem">
            
              <div class="mt-5 md:mt-0 md:col-span-2">
                <div class="shadow sm:rounded-md sm:overflow-hidden">
                  <div class="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <div>
                      <Uploader model="McademyItem" :model_id="mcademyItem.id" @update-add="updateAttachments" />  
                      <PhotoList model="McademyItem" :photos="mcademyItem.Photos" :id="mcademyItem.id" @update-delete="updateAttachments" />
                    </div>
                  </div>    
                </div>
              </div>
            
          </div>
        </div>
          
      </div>
    </div>

    <div class="hidden sm:block" aria-hidden="true">
      <div class="py-5">
        <div class="border-t border-gray-200"></div>
      </div>
    </div>

    <button type="button" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500" @click="destroy">
      <svg v-show="loading" class="animate-spin h-4 mr-2 w-4 rounded-full bg-transparent border-2 border-transparent border-opacity-50" style="border-right-color: white; border-top-color: white;" viewBox="0 0 24 24"></svg>
      {{loading ? 'saving': 'Destroy'}}
    </button>

  </main>
</template>

<script>
import McademyItemService from '../../../services/admin/mcademy_items/service';
import PhotoList from '../../../components/admin/generic/PhotoList';
import Uploader from '../../../components/admin/generic/Uploader';
import McademyItemForm from '../../../components/admin/mcademy_items/McademyItemForm';
import EventProgram from '../../../components/admin/mcademy_program_event/EventProgram';

export default {
  name: 'McademyItemEdit',
  components: {
    PhotoList,
    Uploader,
    McademyItemForm,
    EventProgram
  },
  data() {
    return {
      mcademyItem: null,
      loading: true,
      currentLocale: 'en',
      currentId: this.$route.params.id,
      registrations: []
    }
  },
  methods: {
    async fetchData() {
      this.loading = true;
      return McademyItemService.getItem(this.currentId).then(res => {
        if (res.status !== 200) {
          const error = new Error(res.statusText);
          throw error;
        }
        return res.data;
      })
      .then(json => {
        // set the response data
        this.mcademyItem = json;
      })
      .catch(err => {
        this.error = err;
        // In case a custom JSON error response was provided
        if (err.json) {
          return err.json.then(json => {
            // set the JSON response message
            this.error.message = json.message;
          });
        }
      })
      .then(() => {
        this.loading = false;
      });
    
    },
    async destroy() {
      if(confirm('are you sure?')) {
        this.loading = true;
        await McademyItemService.delete(this.mcademyItem.id);
        this.$router.push(`/mcademy_items/`);
        this.loading = false;
      }
    },
    async getRegistrations() {
      this.loading = true;
      const {data} = await McademyItemService.getRegistrations(this.mcademyItem.id);
      this.registrations = data;
      this.loading = false;
    },
    exportCsv() {
      McademyItemService.getDownload(this.mcademyItem.id).then(function(response) {
        console.log(response.headers)
        var headers = response.headers;
        var blob = new Blob([response.data],{type:headers['content-type']});
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `export_registraties.csv`;
        link.click();
      })
    },
    togglePublished() {
      this.mcademyItem.published = !this.mcademyItem.published;
    },
    async updateAttachments() {
      // todo update attachments only?
      const data = await McademyItemService.getItem(this.$route.params.id);
      this.mcademyItem = data.data;
    }
  },
  mounted() {
    this.fetchData()
  },
}
</script>
